import React, { useState, useRef, useEffect } from 'react'
import { ImageWrap } from '@truphone/gatsby-wrappers'
import { ArrowButton } from '@truphone/buttons'

const VericalContentWrap = (props) => {
  const content = useRef(null)
  const [maxHeight, setMaxHeight] = useState(0)

  useEffect(() => {
    function checkHeight() {
      if (window.innerWidth < 768) {
        setMaxHeight(content.current ? content.current.scrollHeight : 1000)
      }
    }

    checkHeight()
    window.addEventListener('resize', checkHeight)

    return () => {
      window.removeEventListener('resize', checkHeight)
    }
  }, [])

  return (
    <div
      ref={content}
      className="transition-all duration-300 rounded-b-lg bg-white overflow-hidden"
      style={
        props.selected
          ? {
              maxHeight: maxHeight
            }
          : { maxHeight: '0px' }
      }
    >
      <VericalContent selected={props.selected} label={props.label} />
    </div>
  )
}
const VericalContent = (props) => {
  return (
    <div
      className={`list-disc md:flex items-center mb-2 pb-4 px-2 lg:p-0 lg:pl-10 md:pl-10 rounded-b-lg bg-white leading-loose w-full h-full lg:flex lg:items-center`}
    >
      <div
        className="lg:w-1/2 list-margin text-base md:text-xl text-grey-700 "
        dangerouslySetInnerHTML={{ __html: props.label.content }}
      />

      <div className="relative hidden mt-auto lg:inline-flex lg:w-1/2">
        {props.label.icon && (
          <ImageWrap
            className="absolute w-8 h-8 z-2"
            {...props.label.selectedIcon}
            style={{ top: '15%', left: 'auto', right: '62%' }}
            loading="lazy"
          />
        )}
        {props.label.image && <ImageWrap className="max-w-full ml-auto" {...props.label.image} />}
      </div>
    </div>
  )
}

export default function VerticalTabs(props) {
  const [selected, setSelected] = useState(0)

  return (
    <div className="mx-auto max-w-5xl py-10 px-4 text-center">
      {props.title && <h2 className="h3-light mb-4">{props.title}</h2>}
      {props.content && <p className="text-lg lg:text-xl text-gray-700 mb-4">{props.content}</p>}
      {props.cta && (
        <ArrowButton
          className="text-lg text-blue-100 hover:text-blue-100 mb-12 mx-auto font-medium inline-flex"
          href={props.cta.href}
        >
          {props.cta.text}
        </ArrowButton>
      )}

      {props.tabs && (
        <div className="bg-white rounded-2xl flex flex-wrap md:flex-nowrap shadow-xl text-left overflow-hidden">
          <div className="text-white bg-blue-900 px-2 md:py-10 md:pl-4 md:pr-0 rounded-2xl md:rounded-r-none w-full md:w-1/3 lg:w-1/5">
            {props.tabs.map((label, i) => {
              return (
                <div key={`vertical-tab-${i}`}>
                  <div
                    className={`flex justify-between w-full cursor-pointer my-2 px-2 py-1 ${
                      selected === i
                        ? 'rounded-t-lg md:rounded-lg md:rounded-r-none bg-white text-black mb-0 pt-2'
                        : ''
                    }`}
                    onClick={() => {
                      selected === i ? setSelected(null) : setSelected(i)
                    }}
                  >
                    <div className="flex items-center">
                      {label.icon && label.selectedIcon && (
                        <ImageWrap
                          className="w-8 h-8 mr-2"
                          {...(selected === i ? label.selectedIcon : label.icon)}
                          loading="lazy"
                        />
                      )}
                      <h4 className="text-base">{label.title}</h4>
                    </div>
                    <span
                      className={`md:hidden pr-1 ${
                        selected === i ? 'text-black' : 'text-white'
                      } text-3xl`}
                    >
                      {' '}
                      {selected === i ? '-' : '+'}{' '}
                    </span>
                  </div>
                  <div
                    className={`md:hidden relative ${
                      selected === i ? 'visible opacity-100' : 'invisible opacity-0'
                    }`}
                  >
                    <VericalContentWrap
                      key={`vertical-tab-content-${i}`}
                      selected={selected === i}
                      label={label}
                    />
                  </div>
                </div>
              )
            })}
          </div>
          <div className="w-full md:w-2/3 lg:w-4/5 relative">
            {props.tabs.map((label, i) => {
              return (
                <div
                  key={`vertical-tab-content-${i}`}
                  className={`hidden md:block h-full ${
                    selected === i ? 'relative visible opacity-100' : 'absolute invisible opacity-0'
                  }`}
                >
                  <VericalContent selected={selected === i} label={label} />
                </div>
              )
            })}
          </div>
        </div>
      )}
    </div>
  )
}
