import { ArrowButton, OutlinedButtonBlack } from '@truphone/buttons'
import { ImageWrap } from '@truphone/gatsby-wrappers'
import React from 'react'

export default function OneExperienceBlock(props) {
  return (
    <div>
      <div
        className="container mx-auto text-center pt-6 px-6 lg:px-0"
        dangerouslySetInnerHTML={{ __html: props.content }}
      />

      <div className="text-center relative py-10 container mx-auto overflow-hidden">
        {props.backgroundImage && (
          <ImageWrap
            className="absolute top-0 left-1/2 -translate-x-1/2 max-w-3xl"
            {...props.backgroundImage}
          />
        )}

        <div className="relative z-1">
          {props.cta && (
            <OutlinedButtonBlack
              className={`mx-6 mb-10 lg:mb-0`}
              target={props.cta.openTab ? '_blank' : ''}
              href={props.cta.href}
            >
              {props.cta.text}
            </OutlinedButtonBlack>
          )}

          {props.cards && (
            <div className="flex flex-col lg:flex-row">
              {props.cards.map((item, i) => {
                return (
                  <div
                    key={`experience-${i}`}
                    className={`${
                      i === 1 ? 'lg:mt-20' : 'lg:my-0'
                    } my-10 w-full lg:w-1/3 px-3 max-w-xs mx-auto`}
                  >
                    {item.icon && (
                      <ImageWrap className="block mx-auto mb-10" {...item.icon} loading="lazy" />
                    )}

                    {item.title && (
                      <h4 className={`text-xl border-b border-gray-200 pb-4 mb-4 font-medium`}>
                        {item.title}
                      </h4>
                    )}
                    {item.content && <p className={`body1-muted mb-6`}>{item.content}</p>}
                    {item.cta && (
                      <ArrowButton
                        className={`text-blue-100 font-medium text-base md:text-xl justify-center mx-auto`}
                        href={item.cta.href}
                        target={item.cta.openTab ? '_blank' : ''}
                      >
                        {item.cta.text}
                      </ArrowButton>
                    )}
                  </div>
                )
              })}
            </div>
          )}
        </div>
      </div>
    </div>
  )
}
