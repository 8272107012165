import React, { useState, useEffect } from 'react'
import { ImageWrap } from '@truphone/gatsby-wrappers'
import { SecondaryButton } from '@truphone/buttons'
import { Tick } from '@truphone/icons'

export default function CompareBoxes(props) {
  const [selected, setSelected] = useState(1)
  const [desktop, setDesktop] = useState(false)
  useEffect(() => {
    function checkWidth() {
      if (window.innerWidth > 639) {
        setDesktop(true)
      } else {
        setDesktop(false)
      }
    }

    checkWidth()
    window.addEventListener('resize', checkWidth)

    return () => {
      window.removeEventListener('resize', checkWidth)
    }
  }, [])

  return (
    <div className="bg-blue-900 py-20 text-white text-center">
      <div className="container mx-auto px-6 overflow-hidden">
        {props.title && (
          <h2
            className="h3-light lg:whitespace-pre-wrap"
            dangerouslySetInnerHTML={{ __html: props.title }}
          />
        )}
        <div className="relative py-8">
          {props.backgroundImage && (
            <ImageWrap
              {...props.backgroundImage}
              className="h-full block absolute top-0 left-1/2 -translate-x-1/2"
              loading="lazy"
            />
          )}

          {props.boxes && (
            <>
              <div className="relative z-1 inline-flex sm:hidden flex-nowrap w-full lg:max-w-md items-stretch justify-center border-2 border-white rounded-lg overflow-hidden mx-auto mb-10">
                {props.boxes.map((box, i) => {
                  return (
                    <button
                      key={`box-cta-${i}`}
                      onClick={() => setSelected(i)}
                      className={`h-12 relative w-1/2 leading-none text-base text-center ${
                        selected === i ? 'is-active bg-white text-black' : ''
                      }`}
                    >
                      {box.title}
                    </button>
                  )
                })}
              </div>
            </>
          )}

          {props.boxes && (
            <div
              className="relative z-1 flex items-start sm:justify-center sm:items-center max-w-5xl mx-auto
              transition-all ease-out duration-500 compare-width"
              style={{
                transform: desktop ? `translatex(0)` : `translatex(${selected * -320}px)`
              }}
            >
              {props.boxes.map((box, i) => {
                return (
                  <div
                    key={`box-${i}`}
                    className={`${
                      i === 0
                        ? 'sm:py-4 bg-blue-300 sm:max-w-sm sm:mt-36'
                        : 'bg-white text-black sm:max-w-md sm:py-8 ml-4 sm:-ml-8'
                    } py-4 rounded-2xl px-6 text-left max-w-xs w-full sm:w-1/2 `}
                  >
                    {box.title && (
                      <h3
                        className={`${
                          i === 0
                            ? 'border-white border-opacity-20 pb-2'
                            : 'text-black border-grey-200 pb-4'
                        } text-xl border-b font-medium`}
                      >
                        {box.title}
                      </h3>
                    )}
                    {box.list && (
                      <ul className="text-base mt-4 leading-loose">
                        {box.list.map((item, j) => {
                          return (
                            <li key={`compare-list-item-${j}`} className="">
                              <span
                                className={`mr-3 ${
                                  i === 0 ? 'opacity-50' : 'text-blue-300 opacity-90'
                                }`}
                              >
                                {i === 0 ? (
                                  '✕'
                                ) : (
                                  <span className="w-4 h-3 inline-block">
                                    <Tick />
                                  </span>
                                )}
                              </span>
                              {item}
                            </li>
                          )
                        })}
                      </ul>
                    )}
                  </div>
                )
              })}
            </div>
          )}
        </div>
        {props.cta && (
          <SecondaryButton
            href={props.cta.href}
            title={props.cta.title}
            target={props.cta.openTab ? '_blank' : null}
            className="relative z-1 min-w--25"
          >
            {props.cta.text}
          </SecondaryButton>
        )}
      </div>
    </div>
  )
}
