import { ImageWrap } from '@truphone/gatsby-wrappers'
import { Tick } from '@truphone/icons'
import React, { useState } from 'react'
import Title from './Title'

export default function TooltipBox(props) {
  const [visible, setVisible] = useState(null)
  return (
    <div className="py-10 lg:py-20 relative overflow-hidden">
      {props.backgroundImage && (
        <ImageWrap
          className="max-w-none h-3/5 md:h-full absolute top-1/2 left-1/2 -translate-y-1/2 -translate-x-1/2"
          {...props.backgroundImage.url}
        />
      )}
      <div className="relative z-1 max-w max-w-4xl mx-auto px-4 lg:px-10">
        <div className="rounded-2xl bg-white py-8 lg:p-10 md:text-center shadow-lg">
          <div className="max-w-xl mx-auto">
            <Title topTitle={props.topTitle} title={props.title} />
          </div>
          {props.features && (
            <div className="flex flex-wrap max-w-xl mt-8 mx-auto px-6">
              {props.features.map((feature, i) => {
                return (
                  <div
                    className="body1-muted w-full md:w-1/2 lg:px-4 py-2 flex items-center text-left"
                    key={`feature-box-${i}`}
                  >
                    <span className="mr-3 text-black w-4 h-4 inline-block">
                      <Tick />
                    </span>
                    <p className="mr-2">{feature.title}</p>
                    {feature.tooltip && (
                      <div
                        className="relative flex"
                        onMouseEnter={() => setVisible(i)}
                        onMouseLeave={() => setVisible(null)}
                      >
                        <span
                          className="cursor-pointer w-3 h-3 inline-flex justify-center items-center rounded-full bg-blue-300 opacity-60 hover:opacity-100 text-xs text-white"
                          style={{ marginTop: -2 }}
                        >
                          i
                        </span>
                        {visible === i && (
                          <div
                            className="z-1 absolute top-4 left-1/2 -translate-x-1/2 bg-blue-900 text-white text-xs p-2 rounded max-w-sm"
                            style={{ minWidth: 200 }}
                          >
                            {feature.tooltip}
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                )
              })}
            </div>
          )}
        </div>
      </div>
    </div>
  )
}
