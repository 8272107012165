import React from 'react'
import { ImageWrap } from '@truphone/gatsby-wrappers'
import { OutlinedButtonWhite, SecondaryButton } from '@truphone/buttons'

export default function FeaturedBanner(props) {
  return (
    <div
      className="py-10"
      style={{ background: props.bannerBackgroundGradient ? props.bannerBackgroundGradient : null }}
    >
      <div className="relative container mx-auto px-6">
        <div
          className="px-4 md:pl-0 lg:pr-10 rounded-xl flex flex-wrap justify-end bg-blue-300 text-white bg-cover mt-11 w-full"
          style={{
            backgroundImage: props.backgroundImage?.url
              ? `url(${props.backgroundImage?.url})`
              : 'linear-gradient(283deg, #1e1783 -2%, #342dae 24%, #2a9ec2 100%)'
          }}
        >
          <div className={`w-full md:w-1/2 xl:w-2/5 relative flex items-start`}>
            {props.image && (
              <ImageWrap
                className={`
                  md:max-w-lg xl:w-full md:-ml-20 -ml-4 bottom-0 left-0 md:absolute -mt-24`}
                {...props.image}
                loading="lazy"
              />
            )}
          </div>
          <div className="w-full md:w-1/2 xl:w-3/5 py-10 text-center md:text-left">
            {props.title && (
              <h3 className="heron text-4xl lg:text-6xl leading-none mb-4 green-span">
                {props.highlightText && <span>{props.highlightText}</span>} {props.title}
              </h3>
            )}
            <div
              className={`text-base mb-6 ${props.textColor?.hex ? '' : 'opacity-70'}`}
              style={{ color: props.textColor?.hex }}
              dangerouslySetInnerHTML={{ __html: props.content }}
            />
            {props.ctas && (
              <div className="flex flex-wrap -mx-2">
                {props.ctas.map((cta, i) => {
                  return i === 0 ? (
                    <SecondaryButton
                      key={`featued-banner-cta${i}`}
                      className="w-full md:w-auto m-2"
                      href={cta.href}
                      target={cta.openTab ? '_blank' : null}
                    >
                      {cta.text}
                    </SecondaryButton>
                  ) : (
                    <OutlinedButtonWhite
                      key={`featued-banner-cta${i}`}
                      className="w-full md:w-auto m-2"
                      href={cta.href}
                      target={cta.openTab ? '_blank' : null}
                    >
                      {cta.text}
                    </OutlinedButtonWhite>
                  )
                })}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}
