import React, { useState, useEffect } from 'react'
import { ImageWrap } from '@truphone/gatsby-wrappers'
import { SecondaryButton } from '@truphone/buttons'
import './local-number.css'
import './business.css'
import LazyLoad from 'react-lazyload'

const LocalNumber = (props) => {
  return (
    <div className={`animation-fade bg-white p-2 text-2xs ${props.i > 0 ? 'pt-0' : ''}`}>
      <p className={`${props.i > 0 ? 'border-t border-gray-200 pt-2 ' : ''}`}>{props.country}</p>
      <p className="text-blue-100 font-bold">{props.number}</p>
    </div>
  )
}
export default function LocalNumbers(props) {
  const [selected, setSelected] = useState(
    props.countries.filter((x) => x.preselect).map((x) => x.label)
  )
  const [visible, setVisible] = useState([])
  const [seconds, setSeconds] = useState(0)

  useEffect(() => {
    let newVisible = [...visible]

    if (seconds > props.countries.length - 1) {
      setSeconds(0)
      setVisible([])
    }
    const interval = setInterval(() => {
      newVisible.push(props.countries[seconds].label)
      setSeconds((seconds) => seconds + 1)
      setVisible(newVisible)
    }, 2000)

    return () => clearInterval(interval)
  }, [seconds, visible])

  const handleChange = (country) => {
    let newSelected = [...selected]
    if (selected.indexOf(country) < 0) {
      newSelected.push(country)
    } else {
      newSelected = newSelected.filter((item) => item !== country)
    }
    setSelected(newSelected)
  }
  return (
    <div className="pt-20 pb-28 text-white business-gradient">
      <div className="max-w-4xl mx-auto px-6 ">
        <p className="overline-text mb-4 text-center">{props.topTitle}</p>
        <h2 className="h3-light mb-4 text-center leading-tight">{props.title}</h2>

        <h3 className="text-xl mb-4 mt-8 text-center lg:hidden">{props.boxTitle}</h3>
        {props.cta && (
          <SecondaryButton
            targer={props.cta.openTab ? '_blank' : ''}
            href={props.cta.href}
            className="lg:hidden w-full mt-4"
          >
            {props.cta.text}
          </SecondaryButton>
        )}
      </div>

      <LazyLoad height={617}>
        <div className="relative max-w-5xl mx-auto ">
          <div
            className="box-content p-4 relative mx-auto mt-8 lg:mt-0 lg:absolute lg:-left-1 lg:-top-14 w-72 shadow-lg overflow-hidden"
            style={{ height: 617, borderRadius: 57 }}
          >
            {props.phoneOutline && (
              <ImageWrap
                className="absolute top-0 left-0 w-full z-10 pointer-events-none"
                {...props.phoneOutline}
              />
            )}

            <div
              className="box-border pr-1 bg-black z-2 relative h-full rounded-2xl overflow-y-scroll styled-scroll vertical"
              style={{ width: 'calc(100% + 6px)', margin: '-2px 0', height: 'calc(100% + 4px)' }}
            >
              {props.interfaceTop && (
                <ImageWrap className="relative w-full" {...props.interfaceTop} />
              )}
              {selected && (
                <div className="hidden lg:block overflow-hidden text-black rounded-lg mx-1">
                  {selected.map((country, i) => {
                    let number = props.countries.find((x) => x.label === country).number
                    return (
                      <LocalNumber key={`number-${i}`} number={number} country={country} i={i} />
                    )
                  })}
                </div>
              )}
              {visible && (
                <div className="block lg:hidden text-black overflow-hidden rounded-lg mx-1">
                  {visible.map((country, i) => {
                    let number = props.countries.find((x) => x.label === country).number
                    return (
                      <LocalNumber key={`number-${i}`} number={number} country={country} i={i} />
                    )
                  })}
                </div>
              )}
              {props.interfaceBottom && (
                <ImageWrap className="relative" {...props.interfaceBottom} />
              )}
            </div>
          </div>

          <div className="hidden lg:block bg-white text-black px-6 py-10 mt-24 rounded-2xl">
            <div className="pl-80">
              <h3 className="text-xl mb-4">{props.boxTitle}</h3>

              {props.countries && (
                <div className="bg-gray-100 rounded-2xl py-4 flex flex-wrap">
                  {props.countries.map((country, i) => {
                    const selectedItem = selected.find((x) => x === country.label)
                    return (
                      <div key={`local-country-${i}`} className="flex w-1/2 px-5">
                        <label
                          className={`relative flex w-full items-center py-3 justify-between ${
                            i < props.countries.length - 1 ? 'border-b border-gray-200' : ''
                          }`}
                        >
                          <div className="flex">
                            <img
                              className="w-8 h-6 mr-4"
                              src={`/images/flags/${country.iso.toLowerCase()}.svg`}
                              alt={country.iso}
                            />
                            <span>{country.label}</span>
                          </div>
                          <input
                            defaultChecked={selectedItem}
                            className="text-blue-100 form-checkbox rounded-sm"
                            type="checkbox"
                            onChange={() => handleChange(country.label)}
                          />
                        </label>
                      </div>
                    )
                  })}
                </div>
              )}
              {props.cta && (
                <SecondaryButton
                  targer={props.cta.openTab ? '_blank' : ''}
                  href={props.cta.href}
                  className="max-w-xs w-full mt-8"
                >
                  {props.cta.text}
                </SecondaryButton>
              )}
            </div>
          </div>
        </div>
      </LazyLoad>
    </div>
  )
}
