import React, { useState, useEffect } from 'react'
import { Accordion } from '@truphone/accordion'
import { HeadingH2 } from '@truphone/typography-components'
import { geoLookup } from '@truphone/utils'
import { PrimaryButton } from '@truphone/buttons'
import { Search, Select } from '@truphone/core-components'
import './out-of-bundle.css'

export default function OutOfBundle(props) {
  const [loaded, setLoaded] = useState(false)
  const [headers, setHeaders] = useState(null)
  const [rates, setRates] = useState(null)
  const [filteredRates, setFilteredRates] = useState(null)

  const [searchQuery, setSearchQuery] = useState('')
  const [selectedCountry, setSelectedCountry] = useState(null)
  const [footerText, setFooterText] = useState('')
  const [expanded, setExpanded] = useState(false)

  const handleChange = (panel) => {
    setExpanded(panel !== expanded ? panel : false)
  }

  useEffect(() => {
    const geoLookupAsync = async () => {
      const geo = await geoLookup()
      if (geo) {
        const country = props.countries.find((x) => x.iso == geo.iso)
        if (country?.url) {
          setSelectedCountry(country.iso)
        } else {
          setSelectedCountry(props.countries.find((x) => !!x.url)?.iso)
        }
      }
    }

    geoLookupAsync()
  }, [])

  useEffect(() => {
    if (!selectedCountry) {
      setHeaders(null)
      setRates(null)
      setFilteredRates(null)
      setFooterText('')
    }

    const fetchRates = async (url) => {
      const response = await fetch(url)
      if (response.ok) {
        const json = await response.json()
        setHeaders(json.headers)
        setRates(json.rates)
        setFilteredRates(json.rates)
        setSearchQuery('')
        setLoaded(true)
      }
    }

    const country = props.countries.find((x) => x.iso === selectedCountry)
    if (country) {
      if (country.redirect) {
        window.location = country.redirect
      } else {
        fetchRates(country.url)
        setFooterText(country.footerText)
      }
    }
  }, [selectedCountry])

  useEffect(() => {
    if (!rates) {
      return
    }

    setFilteredRates(
      rates.filter(
        (item) => item[1].toString().toLowerCase().indexOf(searchQuery.toLowerCase()) != -1
      )
    )
  }, [searchQuery])

  return (
    <div className="bg-gray-100">
      <div className="h-28 w-full"></div>
      <div className="pt-12 pb-8 lg:pb-12 container mx-auto px-10">
        {props.title && (
          <HeadingH2 className="px-6 lg:px-0 mb-8 text-center">{props.title}</HeadingH2>
        )}

        <div className="flex flex-wrap lg:flex-nowrap justify-center w-full">
          <form autoComplete="off" className="mb-0 w-full sm:w-1/2 relative max-w-xl">
            <input
              type="search"
              name="search"
              placeholder={props.searchPlaceholder}
              className="w-full border border-gray-200 rounded-lg p-3 pl-10 outline-blue-300 "
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
            />
            <Search className="text-blue-300 w-5 absolute left-3 top-1/2 -translate-y-1/2" />
          </form>
          {props.countries && (
            <div className="pt-4 sm:pt-0 sm:pl-4 w-full sm:w-1/2 lg:w-60">
              <Select
                className="bg-white  rounded-lg border border-gray-200"
                options={props.countries.map((x) => ({ text: x.text, value: x.iso }))}
                value={selectedCountry}
                onChange={(value) => setSelectedCountry(value)}
              />
            </div>
          )}
        </div>
      </div>

      <div className="pb-20 container mx-auto px-10">
        <div className="text-sm">
          {filteredRates && filteredRates.length > 0 && (
            <div className="hidden lg:flex w-full text-center font-semibold">
              {headers.map((header, i) => {
                return (
                  i !== 0 && (
                    <span
                      key={`table-header-${i}`}
                      className={`${i === 1 ? 'w-1/6 flex-grow text-left' : 'w-28'} px-2 py-2`}
                    >
                      {i !== 1 && header}
                    </span>
                  )
                )
              })}
            </div>
          )}

          {filteredRates && filteredRates.length > 0 ? (
            <div className={`block w-full lg:overflow-y-auto max-h-600`}>
              <div className="hidden lg:block">
                {filteredRates.map((row, i) => {
                  return (
                    <div className="flex w-full border-b border-gray-200" key={`table-row-${i}`}>
                      {row.map((cell, i) => {
                        return (
                          i !== 0 && (
                            <div
                              key={`table-cell-${i}`}
                              className={`${
                                i !== 1
                                  ? 'text-center w-28 whitespace-pre-line'
                                  : 'font-medium w-1/6 flex-grow'
                              } px-1 py-2`}
                            >
                              {i === 1 ? (
                                <div className="flex items-start" key={`table-cell-${i}`}>
                                  <img
                                    className="flag-shadow w-6 mb-auto mt-1 mr-4 h-auto"
                                    src={`/images/flags/${row[0].toLowerCase()}.svg`}
                                    alt={row[0]}
                                  />
                                  <p className="text-base">{cell}</p>
                                </div>
                              ) : (
                                <p className="text-gray-700">{cell}</p>
                              )}
                            </div>
                          )
                        )
                      })}
                    </div>
                  )
                })}
              </div>
              <div className="block lg:hidden">
                {filteredRates.map((row, i) => {
                  return (
                    <Accordion
                      flag={row[0]}
                      title={row[1]}
                      key={`rates-panel${i}`}
                      expanded={expanded === `rates-panel${i}` || filteredRates.length === 1}
                      onChange={() => handleChange(`rates-panel${i}`)}
                    >
                      <div className="py-4">
                        {row.map((cell, i) => {
                          return (
                            i > 1 && (
                              <div className="flex w-full">
                                <p className="w-1/2 font-bold p-2">{headers[i]}</p>
                                <p className="w-1/2 text-center p-2">{cell}</p>
                              </div>
                            )
                          )
                        })}
                      </div>
                    </Accordion>
                  )
                })}
              </div>
            </div>
          ) : (
            loaded &&
            selectedCountry && (
              <div className="text-center max-w-3xl mx-auto">
                <h2 className="text-2xl mb-4">{props.noResultsTitle}</h2>
                <p className="text-base">{props.noResultsText}</p>

                <PrimaryButton className="mt-8 cursor-pointer" onClick={() => setSearchQuery('')}>
                  {props.noResultsButtonText}
                </PrimaryButton>
              </div>
            )
          )}

          {footerText && <p className="mt-10 text-sm text-grey-700">{footerText}</p>}
        </div>
      </div>
    </div>
  )
}
