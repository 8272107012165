import React, { useState, useEffect } from 'react'
import Title from './Title'
import { ArrowSimple } from '@truphone/icons'

export default function Benefits(props) {
  const [index, setIndex] = useState(0)
  const [disableLeft, setDisableLeft] = useState(index === 0)
  const [disableRight, setDisableRight] = useState(index === -props.benefits.length + 1)
  const [showArrow, setShowArrows] = useState(false)
  const slideWidth = 208
  let carouselWidth = props.benefits.length * slideWidth

  useEffect(() => {
    function checkWidth() {
      if (carouselWidth > window.innerWidth) {
        setShowArrows(true)
      }
    }

    checkWidth()
    window.addEventListener('resize', checkWidth)

    return () => {
      window.removeEventListener('resize', checkWidth)
    }
  }, [carouselWidth])

  function moveFeatures(number) {
    if (carouselWidth + (index + number) * slideWidth - window.innerWidth > 0) {
      setDisableRight(false)
    } else {
      setDisableRight(true)
    }

    if (index + number === 0) {
      setDisableLeft(true)
      setDisableRight(false)
    } else {
      setDisableLeft(false)
    }
    setIndex(index + number)
  }
  return (
    <div className="py-20">
      <div className="max-w-3xl mx-auto text-center">
        <Title title={props.title} subtitle={props.subtitle} />
        <div
          className={`${showArrow ? 'flex' : 'hidden'} mt-4 justify-center mx-auto text-blue-100`}
        >
          <div
            onClick={() => !disableLeft && moveFeatures(1)}
            className={`border-2 ${
              disableLeft ? 'opacity-30' : 'cursor-pointer opacity-100'
            } border-blue-100 rounded-full h-11 w-11 flex justify-center items-center m-3`}
          >
            <ArrowSimple className="w-4 h-4 rotate-90"></ArrowSimple>
          </div>
          <div
            onClick={() => !disableRight && moveFeatures(-1)}
            className={`border-2 ${
              disableRight ? 'opacity-30' : 'cursor-pointer opacity-100'
            } border-blue-100 rounded-full h-11 w-11 flex justify-center items-center m-3`}
          >
            <ArrowSimple className="w-4 h-4 -rotate-90"></ArrowSimple>
          </div>
        </div>
      </div>
      {props.benefits && (
        <div className="w-full overflow-hidden pb-5">
          <div
            className="flex flex-nowrap justify-start max-w-none transition-all ease-out duration-500"
            style={{ transform: `translatex(${index * slideWidth}px)`, width: carouselWidth + 200 }}
          >
            {props.benefits.map((benefit, i) => {
              return (
                <div
                  key={`benefit-${i}`}
                  className="bg-white rounded-2xl shadow-xl p-4 pb-6 w-44 m-4"
                >
                  <div className="h-6 w-6 border-2 text-blue-300 text-sm border-blue-300 rounded-full flex justify-center items-center">
                    ✔
                  </div>
                  <p className="text-sm mt-4 leading-4">{benefit}</p>
                </div>
              )
            })}
          </div>
        </div>
      )}
    </div>
  )
}
