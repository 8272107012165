import React from 'react'
import { uppercaseString } from '@truphone/utils'

export default function BusinessContact(props) {
  return (
    <div className="bg-gray-100">
      <div className="w-full business-gradient py-16"></div>
      <div className="container mx-auto px-6 pt-28 text-center">
        <p className="overline-muted mb-4">{uppercaseString(props.topTitle)}</p>
        <h2 className="h3-light text-4xl tracking-normal">{props.title}</h2>
      </div>
    </div>
  )
}
