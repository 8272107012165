import React, { useState, useEffect, useRef } from 'react'
import { OutlinedButtonWhite, SecondaryButton } from '@truphone/buttons'
import { ArrowSimple } from '@truphone/icons'
import { uppercaseString } from '@truphone/utils'
import { ImageWrap } from '@truphone/gatsby-wrappers'

const OneItemContent = (props) => {
  return (
    <div className={`text-white pt-8`}>
      {props.content && (
        <div
          className={`${props.ctas.length > 1 ? 'h5-light' : 'text-base p-margin'} mb-8`}
          dangerouslySetInnerHTML={{ __html: props.content }}
        />
      )}
      {props.ctas &&
        props.ctas.map((cta, i) => {
          return i === 0 ? (
            <SecondaryButton
              targer={cta.openTab ? '_blank' : ''}
              key={`cta-one-${i}`}
              href={cta.href}
              className={`mb-4 w-full`}
            >
              {cta.text}
            </SecondaryButton>
          ) : (
            <OutlinedButtonWhite
              targer={cta.openTab ? '_blank' : ''}
              key={`cta-one-${i}`}
              href={cta.href}
              className="text-white w-full"
            >
              {cta.text}
            </OutlinedButtonWhite>
          )
        })}
    </div>
  )
}

const OneItem = (props) => {
  return (
    <div
      className={`${props.expanded ? 'opacity-100' : 'opacity-40'} 
      ${props.border ? 'border-t border-white border-opacity-20 lg:border-0' : ''}
      mx-10 hover:opacity-100 transition-all duration-300 cursor-pointer my-6 text-center pt-6 lg:pt-0 lg:text-left`}
    >
      <h3
        onClick={props.onChange}
        className="heron-regular font-semibold tracking-medium flex flex-col md:block text-2xl text-white lg:pl-14"
      >
        {props.oneText && (
          <span
            className={`${
              props.expanded ? 'text-green-100 w-12' : ''
            } relative flex mx-auto md:inline-block`}
          >
            {uppercaseString(props.oneText)}
            <img
              className={`${
                props.expanded ? 'block' : 'hidden'
              } absolute -top-1 -left-3 md:-left-2 lg:-left-3 max-w-none`}
              src="/images/blocks/brush.svg"
              alt=""
              style={{ width: '4.25rem' }}
            />{' '}
          </span>
        )}
        {uppercaseString(props.title)}
        <ArrowSimple
          className={`lg:hidden w-3 mx-auto mt-2 transform ${props.expanded ? 'hidden' : ''}`}
        />
      </h3>

      <div
        style={props.expanded ? { maxHeight: 1000 } : { maxHeight: '0px' }}
        className={`overflow-hidden transition-all lg:hidden`}
      >
        <OneItemContent {...props} />
      </div>
    </div>
  )
}
let timeoutHandle = undefined
export default function OneBlock(props) {
  const progressBar = useRef(null)
  const [running, setRunning] = useState(true)
  const [selected, setSelected] = useState(0)

  const duration = props.duration || 7000

  const advance = (progressBar) => {
    if (progressBar.current) {
      progressBar.current.classList.remove('animate-width')
      void progressBar.current.offsetWidth //Odd but it works
      progressBar.current.classList.add('animate-width')
      setSelected((prev) => {
        return prev === props.tabs.length - 1 ? 0 : prev + 1
      })
    }
  }

  useEffect(() => {
    if (window.innerWidth > 1024 && running) {
      timeoutHandle = setInterval(() => advance(progressBar), duration)
    } else {
      setRunning(false)
      clearInterval(timeoutHandle)
    }
  }, [running])

  return (
    <div className="max-w max-w-5xl mx-auto mt-8 px-6">
      {props.tabs && (
        <div className="relative business-gradient rounded-2xl overflow-hidden">
          {props.backgroundImage && (
            <ImageWrap
              className="absolute top-0 left-0 w-full h-full object-cover"
              {...props.backgroundImage}
            />
          )}
          <div className="relative z-1">
            <div
              ref={progressBar}
              className={`h-1 bg-green-100 ${running ? 'animate-width' : ''}`}
              style={{ animationDuration: duration / 1000 + 's' }}
            ></div>
            <div className="flex flex-wrap items-center">
              <div className="w-full lg:w-1/2 lg:py-10">
                {props.tabs.map((item, i) => {
                  return (
                    <OneItem
                      key={`onebox-item${i}`}
                      onChange={() => {
                        setSelected(i)
                        setRunning(false)
                      }}
                      {...item}
                      border={i !== 0}
                      expanded={selected === i}
                    />
                  )
                })}
              </div>
              {props.tabs.map((item, i) => {
                return (
                  <div
                    key={`oneblock-content-${i}`}
                    className={`${
                      selected === i ? 'hidden lg:block' : 'hidden'
                    } w-1/2 text-white py-10 px-8`}
                  >
                    <OneItemContent {...item} />
                  </div>
                )
              })}
            </div>
          </div>
        </div>
      )}
    </div>
  )
}
