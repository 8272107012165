import React from 'react'
import { OutlinedButtonBlack } from '@truphone/buttons'
import { ImageWrap } from '@truphone/gatsby-wrappers'
import './business.css'

export default function CaseStudy(props) {
  return (
    <div className={`${props.topLogo || props.topText ? 'py-10 lg:py-20' : 'pb-10 lg:pb-20'}`}>
      <div className="max-w-6xl pl-6 lg:px-6 mx-auto relative">
        {props.image && props.image.url && (
          <div className={`max-w-585 pt-28 ml-4 md:ml-auto`}>
            <ImageWrap
              className="lg:rounded-2xl overflow-hidden w-full"
              {...props.image}
              loading="lazy"
            />
          </div>
        )}
        <div className="relative -top-8 left-0 pr-6 lg:absolute lg:top-16 lg:left-4">
          <div
            className={`max-w-585 bg-gray-100 p-4 pt-8 lg:p-6
              border-2 border-blue-900 border-t-4 rounded-b-2xl w-full`}
            style={{ borderColor: props.borderTopColor }}
          >
            <div className={``}>
              {props.logo && (
                <div className={`flex items-start mb-4 h-16`}>
                  <ImageWrap className="block" {...props.logo} loading="lazy" />
                </div>
              )}
              {props.title && (
                <h3
                  className={`md:text-3xl text-xl lg:mt-4 mb-8 lg:mr-28 ${
                    props.titleStyle === 'h3' ? 'h3-light' : 'font-semibold'
                  }`}
                >
                  {props.title}
                </h3>
              )}
              {props.content && (
                <div
                  className={`${
                    props.logo || props.contentStyle === 'body1'
                      ? 'text-base mb-4'
                      : 'text-xl lg:text-2xl mb-4'
                  } text-grey-700 p-margin`}
                  dangerouslySetInnerHTML={{ __html: props.content }}
                />
              )}
              {props.author && (
                <div className="mt-8 text-right">
                  <p className="text-xs md:text-base mb-2 text-grey-700 uppercase tracking-widest font-semibold">
                    {props.author.name}
                  </p>
                  <p className="text-sm">{props.author.title}</p>
                </div>
              )}
            </div>

            {props.cta && (
              <OutlinedButtonBlack
                className={`w-full mt-4 `}
                href={props.cta.href}
                target={props.cta.openTab ? '_blank' : ''}
              >
                {props.cta.text}
              </OutlinedButtonBlack>
            )}
          </div>
        </div>

        {!props.topLogo && props.topText && (
          <div
            className={`absolute top-0 left-0 lg:left-1/2 lg:-translate-x-1/2 ml-4 text-white flex flex-col w-64 p-4 business-gradient rounded-2xl`}
          >
            <p className="text-6xl heron">{props.topText.title}</p>
            <p className="text-2xl mt-1 leading-tight">{props.topText.content}</p>
          </div>
        )}

        {props.topLogo && (
          <div
            className={`absolute top-0 left-0 lg:left-1/2 lg:-translate-x-1/2 ml-4 text-white flex h-40 w-48 items-center p-6 business-gradient rounded-2xl`}
          >
            <ImageWrap className="max-w-full" {...props.topLogo} loading="lazy" />
          </div>
        )}
      </div>
    </div>
  )
}
