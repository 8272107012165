import React, { useState, useEffect } from 'react'
import { ImageWrap } from '@truphone/gatsby-wrappers'
import { ArrowSimple, Arrow } from '@truphone/icons'
import './feature.css'

const FeatureCard = (props) => {
  return (
    <div
      ref={props.ref}
      className={`h-full text-center rounded-b-xl border-t-4 border-green-100 ${
        props.horizontalLine
          ? 'p-10 bg-blue-900 text-white'
          : 'flex flex-col shadow-md px-4 py-6 text-black bg-white '
      } ${props.feature.cta ? 'hover:bg-gray-50 hover:border-black' : ''}`}
      style={{ background: props.backgroundColor, color: props.textColor }}
    >
      {props.feature.icon && (
        <div
          className={`${
            props.horizontalLine ? 'border-b border-white border-opacity-30 mb-4' : ''
          } w-full`}
        >
          <ImageWrap className="m-auto mb-4 w-10" {...props.feature.icon} loading="lazy" />
        </div>
      )}
      {props.feature.title && (
        <h4
          className={`mb-4 text-xl font-medium`}
          dangerouslySetInnerHTML={{ __html: props.feature.title }}
        />
      )}
      {props.feature.content && (
        <div
          className={` ${props.horizontalLine ? 'text-xl lg:text-2xl' : 'body1-muted mb-6'}`}
          style={{ color: props.textColor }}
          dangerouslySetInnerHTML={{ __html: props.feature.content }}
        />
      )}
      {props.feature.cta && (
        <div className="text-lg text-blue-100 font-medium inline-flex items-center mt-auto mx-auto">
          {props.feature.cta.text}
          <Arrow className="ml-2 w-4" />
        </div>
      )}
    </div>
  )
}

export default function FeaturesContainer(props) {
  const [index, setIndex] = useState(0)
  const [slideWidth, setSlideWidth] = useState(416)
  const [sideWidth, setSideWidth] = useState(0)
  const [disableLeft, setDisableLeft] = useState(index === 0)
  const [disableRight, setDisableRight] = useState(index === -props.features.length + 1)
  let carouselWidth = props.features.length * slideWidth

  function moveFeatures(number) {
    if (carouselWidth + (index + number) * slideWidth - (window.innerWidth - sideWidth) > 0) {
      setDisableRight(false)
    } else {
      setDisableRight(true)
    }

    if (index + number === 0) {
      setDisableLeft(true)
      setDisableRight(false)
    } else {
      setDisableLeft(false)
    }
    setIndex(index + number)
  }
  let resizeTimer
  useEffect(() => {
    const resizeSlider = () => {
      clearTimeout(resizeTimer)
      if (props.scrollContainerOnMobile) {
        let sideWidth = document.querySelector('.network-carousel').getBoundingClientRect().left
        setSideWidth(sideWidth)
      }

      resizeTimer = setTimeout(function () {
        if (window.innerWidth < 500) {
          setSlideWidth(332)
        } else {
          setSlideWidth(416)
          setIndex(0)
        }
      }, 250)
    }

    resizeSlider()
    window.addEventListener('resize', resizeSlider)

    return () => {
      window.removeEventListener('resize', resizeSlider)
    }
  }, [slideWidth])

  return (
    <div
      className={`${props.scrollContainerOnMobile ? 'pb-20 pt-10' : ''} ${
        props.negativeMarginTop ? '-mt-32' : 'py-10'
      } overflow-hidden`}
    >
      <div className="container mx-auto px-6 text-center">
        <div className="xl:hidden">
          {props.features && props.scrollContainerOnMobile && (
            <div className={`network-carousel flex -mx-3 text-blue-300 mb-4`}>
              <div
                onClick={() => !disableLeft && moveFeatures(1)}
                className={`border-2 ${
                  disableLeft ? 'opacity-30' : 'cursor-pointer opacity-100'
                } border-blue-300 rounded-full h-11 w-11 flex justify-center items-center m-3`}
              >
                <ArrowSimple className="w-4 h-4 rotate-90"></ArrowSimple>
              </div>
              <div
                onClick={() => !disableRight && moveFeatures(-1)}
                className={`border-2 ${
                  disableRight ? 'opacity-30' : 'cursor-pointer opacity-100'
                } border-blue-300 rounded-full h-11 w-11 flex justify-center items-center m-3`}
              >
                <ArrowSimple className="w-4 h-4 -rotate-90"></ArrowSimple>
              </div>
            </div>
          )}
        </div>
        {props.features && (
          <div
            className={`flex -mx-4  ${
              props.scrollContainerOnMobile
                ? 'carousel-width flex-nowrap xl:flex-wrap transition-all ease-out duration-500'
                : 'flex-wrap items-stretch'
            } ${props.features.length % 3 !== 0 ? 'lg:flex-nowrap' : ''}`}
            style={{ transform: `translatex(${index * slideWidth}px)` }}
          >
            {props.features.map((feature, i) => {
              return feature.cta ? (
                <a
                  href={feature.cta.href}
                  key={`feature-item-${i}`}
                  className={`w-full shadow-coverage rounded-b-xl overflow-hidden ${
                    props.scrollContainerOnMobile
                      ? 'w-[300px] sm:w-full sm:max-w-sm m-4 '
                      : 'sm:w-1/2 lg:w-1/3 p-2 lg:p-4 '
                  }`}
                >
                  <FeatureCard feature={feature} {...props} />
                </a>
              ) : (
                <div
                  key={`feature-item-${i}`}
                  className={`w-full ${
                    props.scrollContainerOnMobile
                      ? 'w-[300px] sm:w-full sm:max-w-sm m-4 '
                      : 'sm:w-1/2 lg:w-1/3 p-2 lg:p-4 '
                  }`}
                >
                  <FeatureCard feature={feature} {...props} />
                </div>
              )
            })}
          </div>
        )}
      </div>
    </div>
  )
}
