import React from 'react'
import { uppercaseString } from '@truphone/utils'

export default function Title(props) {
  return (
    <div className="px-6">
      {props.topTitle && (
        <p
          className={`px-6 lg:px-0 text-xs md:text-base font-semibold tracking-widest mb-4 text-grey-700`}
          style={{ color: props.textColor?.hex }}
        >
          {uppercaseString(props.topTitle)}
        </p>
      )}
      {props.title && (
        <h2 className="h3-light px-6 lg:px-0 mb-4" style={{ color: props.textColor?.hex }}>
          {props.title}
        </h2>
      )}
      {props.subtitle && (
        <h3 className="h5-light px-6 lg:px-0 text-grey-700" style={{ color: props.textColor?.hex }}>
          {props.subtitle}
        </h3>
      )}
      {props.text && (
        <div className="text-base link-blue" dangerouslySetInnerHTML={{ __html: props.text }} />
      )}
    </div>
  )
}
