import React, { useState, useEffect, useRef } from 'react'
import { VideoModal } from '@truphone/modals'
import { ArrowSimple } from '@truphone/icons'
import { uppercaseString } from '@truphone/utils'
import { ArrowButton, OutlinedButtonWhite, SecondaryButton } from '@truphone/buttons'
import { ImageWrap } from '@truphone/gatsby-wrappers'
import FeatureCard from './FeatureCard'

export default function ScrollCardContainer(props) {
  const [index, setIndex] = useState(0)
  const [videoURL, setVideoURL] = useState(0)
  const [play, setPlay] = useState(false)
  const [stopAnimation, setStopAnimation] = useState(false)
  const [slideWidth, setSlideWidth] = useState(
    props.numbers ? 320 : props.business ? 288 : props.darkCard ? 750 : 332
  )
  const [sideWidth, setSideWidth] = useState(0)
  const [disableLeft, setDisableLeft] = useState(index === 0)
  const [disableRight, setDisableRight] = useState(
    !props.loop && index === -props.features.length + 1
  )

  const container = useRef(null)
  const firstCard = useRef(null)

  function setIsPlaying(i) {
    setVideoURL(i)
    const pageMain =
      (document && document.querySelector('.page-main')) || document.querySelector('#root')
    if (pageMain) {
      pageMain.style.zIndex = 2147483641
      setPlay(true)
    }
  }

  let carouselWidth = props.features.length * slideWidth
  let featuresItems = props.loop
    ? [...props.features, ...props.features, ...props.features]
    : props.features

  const moveFeatures = (number) => {
    let leftMargin = container.current.getBoundingClientRect().left
    let slideWidth = firstCard.current?.clientWidth

    if (carouselWidth + (index + number) * slideWidth - (window.innerWidth - leftMargin) > 0) {
      setDisableRight(false)
    } else if (!props.loop) {
      setDisableRight(true)
    }

    if (index + number === 0) {
      setDisableLeft(true)
      setDisableRight(false)
    } else {
      setDisableLeft(false)
    }
    if (props.loop && index === -props.features.length * 2 + 1) {
      setIndex(index + number)
      setTimeout(() => {
        setIndex(-props.features.length)
        setStopAnimation(true)
      }, 500)
    } else {
      setStopAnimation(false)
      setIndex(index + number)
    }
  }

  useEffect(() => {
    setSlideWidth(firstCard.current?.clientWidth)
  }, [firstCard])

  useEffect(() => {
    var xDown = null
    var yDown = null

    function getTouches(evt) {
      return evt.touches || evt.originalEvent.touches
    }

    function handleTouchStart(evt) {
      const firstTouch = getTouches(evt)[0]
      xDown = firstTouch.clientX
      yDown = firstTouch.clientY
    }

    function handleTouchMove(evt) {
      if (!xDown || !yDown) {
        return
      }

      var xUp = evt.touches[0].clientX
      var yUp = evt.touches[0].clientY

      var xDiff = xDown - xUp
      var yDiff = yDown - yUp

      if (Math.abs(xDiff) > Math.abs(yDiff)) {
        if (xDiff > 0 && index > -props.features.length + 1) {
          moveFeatures(-1)
        } else if (xDiff < 0 && index < 0) {
          moveFeatures(1)
        }
      }
      xDown = null
      yDown = null
    }

    document.addEventListener('touchstart', handleTouchStart)
    document.addEventListener('touchmove', handleTouchMove)

    return () => {
      document.removeEventListener('touchstart', handleTouchStart)
      document.removeEventListener('touchmove', handleTouchMove)
    }
  }, [index])

  useEffect(() => {
    let resizeTimer
    let windowWidth = window.innerWidth
    const resizeSlider = () => {
      clearTimeout(resizeTimer)
      if (windowWidth !== window.innerWidth) {
        let sideWidth = document.querySelector('.feature-carousel').getBoundingClientRect().left
        if (!props.numbers) {
          setSideWidth(sideWidth)
        }

        resizeTimer = setTimeout(function () {
          if (!props.numbers && !props.business) {
            if (window.innerWidth < 640) {
              setSlideWidth(332)
            } else {
              setSlideWidth(416)
            }
          }
          if (window.innerWidth > 400) {
            setIndex(0)
            setDisableLeft(true)
            setDisableRight(false)
          }
        }, 250)
      }
    }
    resizeSlider()
    window.addEventListener('resize', resizeSlider)

    return () => {
      window.removeEventListener('resize', resizeSlider)
    }
  }, [])

  return (
    <>
      {props.features && (
        <div
          className={`py-20 overflow-hidden ${props.business ? 'business-gradient' : ''} ${
            props.numbers ? 'lg:text-center' : ''
          }`}
          style={{
            color: props.textColor && props.textColor.hex
          }}
        >
          <div className="container mx-auto px-6">
            {props.topTitle && (
              <p
                className="overline-text mb-4 text-grey-700"
                style={{
                  color: props.textColor && props.textColor.hex
                }}
              >
                {uppercaseString(props.topTitle)}
              </p>
            )}
            {props.title && (
              <h2
                className={`${props.business ? 'max-w-xl' : ''} h3-light mb-4 whitespace-pre-wrap`}
              >
                {props.oneText && (
                  <span className="inline-block heron text-4xl uppercase text-green-100 relative mr-2">
                    {props.oneText}
                    <img
                      className={`block absolute -top-1 -left-4 w-24 max-w-none`}
                      src="/images/blocks/brush.svg"
                      alt=""
                    />
                  </span>
                )}
                {props.title}
              </h2>
            )}
            {props.content && (
              <p className="text-lg lg:text-xl text-gray-700 mb-4">{props.content}</p>
            )}
            {props.cta && (
              <>
                {props.business ? (
                  <OutlinedButtonWhite
                    className={`mt-4 mb-6`}
                    href={props.cta.href}
                    target={props.cta.openTab ? '_blank' : ''}
                  >
                    {' '}
                    {props.cta.text}
                  </OutlinedButtonWhite>
                ) : (
                  !props.numbers && (
                    <ArrowButton
                      className={`text-blue-100 text-lg mb-8 font-medium`}
                      href={props.cta.href}
                      target={props.cta.openTab ? '_blank' : ''}
                    >
                      {props.cta.text}
                    </ArrowButton>
                  )
                )}
              </>
            )}

            {props.features && (
              <div
                ref={container}
                className={`feature-carousel flex -mx-3 ${
                  props.business ? 'text-green-100 ' : 'text-blue-100 '
                } ${props.numbers ? 'xl:hidden' : ''} mb-4`}
              >
                <div
                  onClick={() => !disableLeft && moveFeatures(1)}
                  className={`border-2 ${
                    disableLeft ? 'opacity-30' : 'cursor-pointer opacity-100'
                  } ${
                    props.business ? 'border-green-100 ' : 'border-blue-100 '
                  } rounded-full h-11 w-11 flex justify-center items-center m-3`}
                >
                  <ArrowSimple className="w-4 h-4 rotate-90"></ArrowSimple>
                </div>
                <div
                  onClick={() => !disableRight && moveFeatures(-1)}
                  className={`border-2 ${
                    disableRight ? 'opacity-30' : 'cursor-pointer opacity-100'
                  } ${
                    props.business ? 'border-green-100 ' : 'border-blue-100 '
                  } rounded-full h-11 w-11 flex justify-center items-center m-3`}
                >
                  <ArrowSimple className="w-4 h-4 -rotate-90"></ArrowSimple>
                </div>
              </div>
            )}

            <div className="relative">
              <div
                className={`max-w-none flex flex-nowrap ${
                  props.darkCard ? 'items-stretch' : 'items-start'
                } ${props.numbers ? '-mx-6' : '-mx-4'}
                ${props.loop && stopAnimation ? '' : 'transition-all ease-out duration-500'}`}
                style={{
                  transform: `translatex(${index * slideWidth}px)`,
                  width: `${slideWidth * featuresItems.length}px`
                }}
              >
                {props.business || props.numbers
                  ? featuresItems.map((feature, i) => {
                      return (
                        <div
                          key={`features-${i}`}
                          ref={i === 0 ? firstCard : null}
                          className={`${
                            props.numbers ? 'text-left xl:text-center mx-4 p-4' : 'px-2'
                          } w-72 flex-shrink-0`}
                        >
                          <div className="relative">
                            {props.numbers && (
                              <span className="rounded-full h-11 w-11 justify-center items-center inline-flex mx-auto my-3 border-2 border-blue-300 text-blue-300 text-2xl heron">
                                {i + 1}
                              </span>
                            )}
                            {feature.image && (
                              <ImageWrap
                                className="rounded-lg bg-navy opacity-40"
                                {...feature.image}
                              />
                            )}
                            {feature.videoUrl && (
                              <div
                                onClick={() => setIsPlaying(feature.videoUrl)}
                                className="cursor-pointer absolute top-0 left-0 text-white w-full h-full flex justify-center items-center"
                              >
                                <img
                                  width="80"
                                  height="80"
                                  src="/images/icons/icon-play.svg"
                                  alt="play"
                                />
                              </div>
                            )}
                          </div>
                          {feature.title && (
                            <h3
                              className={`text-xl mb-4 ${
                                props.numbers
                                  ? 'mt-2 h-20 lg:h-auto font-medium border-b pb-4 border-grey-200 lg:border-0 lg:pb-0'
                                  : 'mt-6 font-medium'
                              }`}
                            >
                              {feature.title}
                            </h3>
                          )}
                          {feature.content && (
                            <div
                              className={`text-base p-margin ${
                                props.numbers ? 'text-grey-700' : ''
                              }`}
                              dangerouslySetInnerHTML={{ __html: feature.content }}
                            />
                          )}
                        </div>
                      )
                    })
                  : props.darkCard
                  ? featuresItems.map((feature, i) => {
                      const card = (
                        <div
                          className={`w-[300px] sm:w-[500px] md:w-full h-full flex flex-col max-w-3xl p-6 bg-blue-900 ${
                            feature.cta ? 'hover:bg-black' : ''
                          } text-white rounded-2xl shadow-coverage`}
                        >
                          <span className="heron text-green-100 text-7xl leading-none block -mb-8">
                            “
                          </span>
                          <h5
                            className="text-2xl font-light"
                            dangerouslySetInnerHTML={{ __html: feature.content }}
                          />
                          <span className="heron text-green-100 text-7xl leading-none text-right block -mb-8">
                            ”
                          </span>

                          {feature.image && (
                            <ImageWrap className="m-auto -ml-4 mb-0" {...feature.image} />
                          )}
                          {feature.author && (
                            <p className="text-base uppercase">{feature.author}</p>
                          )}
                        </div>
                      )

                      return (
                        <div
                          key={`customer-story-feature-${i}`}
                          className="mx-4 h-inherit"
                          ref={i === 0 ? firstCard : null}
                        >
                          <>
                            {feature.cta ? (
                              <a
                                title={feature.cta.title}
                                href={feature.cta.href}
                                target={feature.cta.openTab ? '_blank' : null}
                              >
                                {card}
                              </a>
                            ) : (
                              <>{card}</>
                            )}
                          </>
                        </div>
                      )
                    })
                  : featuresItems.map((feature, i) => {
                      return (
                        <div key={`feature-${i}`} ref={i === 0 ? firstCard : null}>
                          <FeatureCard
                            {...feature}
                            small={true}
                            showCardCtaBelowFooter={props.showCardCtaBelowFooter}
                            expand={props.expand}
                          />
                        </div>
                      )
                    })}
              </div>
            </div>
            {props.cta && props.numbers && (
              <SecondaryButton
                className={`mt-5 mb-6`}
                href={props.cta.href}
                target={props.cta.openTab ? '_blank' : ''}
              >
                {' '}
                {props.cta.text}
              </SecondaryButton>
            )}
          </div>
        </div>
      )}
      {props.features && <VideoModal isOpen={play} handleOpen={() => setPlay()} href={videoURL} />}
    </>
  )
}
