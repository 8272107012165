import React, { useState, useRef } from 'react'
import { SecondaryButton, OutlinedButtonBlack } from '@truphone/buttons'
import { ArrowSimple, Arrow } from '@truphone/icons'
import { ImageWrap } from '@truphone/gatsby-wrappers'
import './feature.css'

export default function FeatureCard(props) {
  const [expanded, setExpanded] = useState(false)
  const ref = useRef(null)

  const setHeightAuto = () => {
    setExpanded(true)
    ref.current.style.maxHeight = '100%'
  }

  return (
    <div className="p-4">
      <div
        className={`${
          props.small
            ? 'flex flex-col bg-white px-4 py-6 w-[300px] sm:w-[384px] shadow-md rounded'
            : props.maxWidth
            ? `max-w-585 bg-gray-100 p-6 ${props.author ? '' : 'pt-16'} border-2 border-blue-900`
            : 'bg-gray-100 p-6 mx-auto mt-8 lg:w-auto lg:mt-0 w-full'
        } text-black border-t-4 border-green-100 rounded-b-2xl ${
          props.equalHeight ? 'h-full' : ''
        }`}
        style={{ borderColor: props.borderTopColor }}
      >
        <div className={`${props.small && props.content ? 'min-h-150' : ''}`}>
          {props.logo && props.author && (
            <div className="flex items-start pt-4 h-16">
              <ImageWrap className="block" {...props.logo} />
            </div>
          )}
          {props.image && (
            <div className="flex items-center my-1 h-20">
              <ImageWrap objectFit="contain" className="block max-h-full" {...props.image} />
            </div>
          )}
          {props.title && <h3 className={`lg:text-2xl text-xl font-medium mb-4`}>{props.title}</h3>}
          {props.content && (
            <div
              className={`p-margin link-blue ${
                props.maxWidth
                  ? props.author
                    ? 'text-base mb-4'
                    : 'text-xl font-medium mb-4'
                  : 'text-base'
              } ${props.small ? 'text-black' : 'text-grey-700'}`}
              dangerouslySetInnerHTML={{ __html: props.content }}
            />
          )}
          {props.author && (
            <div className="mt-8 text-right">
              <p className="text-xs md:text-base mb-2 text-grey-700 uppercase tracking-widest font-bold">
                {props.author.name}
              </p>
              <p className="text-sm">{props.author.title}</p>
            </div>
          )}
        </div>

        {!props.showCardCtaBelowFooter &&
          props.ctas &&
          props.ctas.map((cta, i) => {
            return cta.outlined ? (
              <OutlinedButtonBlack
                key={`cta-${i}`}
                className={`${props.maxWidth ? 'w-full md:w-auto' : 'w-full'} mt-4`}
                href={cta.href}
                targer={cta.openTab ? '_blank' : ''}
              >
                {cta.text}
              </OutlinedButtonBlack>
            ) : (
              <SecondaryButton
                key={`cta-${i}`}
                className="mt-4 w-full"
                href={cta.href}
                targer={cta.openTab ? '_blank' : ''}
              >
                {cta.text}
              </SecondaryButton>
            )
          })}

        {props.goToSection && (
          <a href={props.hash} className="text-lg text-blue-100 font-medium flex mx-auto">
            {props.goToSection}
            <Arrow className="ml-4 -rotate-90 w-4" />
          </a>
        )}
        {props.footer && (
          <div
            ref={ref}
            className={`max-h-48 mb-4 relative mt-8 ${props.id ? `${props.id}` : ''} ${
              expanded ? '' : 'expand-gradient overflow-hidden'
            }`}
          >
            <div
              className="list-tick p-margin body1-muted link-blue"
              dangerouslySetInnerHTML={{ __html: props.footer }}
            />
            {props.showCardCtaBelowFooter &&
              props.ctas &&
              props.ctas.map((cta, i) => {
                return cta.outlined ? (
                  <OutlinedButtonBlack
                    key={`cta-${i}`}
                    className={`${props.maxWidth ? 'w-full md:w-auto' : 'w-full'} mt-4`}
                    href={cta.href}
                    targer={cta.openTab ? '_blank' : ''}
                  >
                    {cta.text}
                  </OutlinedButtonBlack>
                ) : (
                  <SecondaryButton
                    key={`cta-${i}`}
                    className="mt-4 w-full"
                    href={cta.href}
                    targer={cta.openTab ? '_blank' : ''}
                  >
                    {cta.text}
                  </SecondaryButton>
                )
              })}
          </div>
        )}

        {props.expand && !expanded && (
          <span
            className="text-blue-100 no-underline flex m-auto mt-4 mb-0 cursor-pointer"
            onClick={() => setHeightAuto()}
          >
            {props.expand}
            <ArrowSimple className="w-4 h-6 ml-2" />
          </span>
        )}
      </div>
    </div>
  )
}
