import { ImageWrap } from '@truphone/gatsby-wrappers'
import React from 'react'
import './business.css'

export default function HighlightBox(props) {
  return (
    <div className={`${props.bgWhite ? 'bg-white' : 'bg-gray-100'}`}>
      <div className="container mx-auto px-10">
        {props.highlights && (
          <div className="p-10 business-gradient text-white rounded-2xl">
            <div className="flex flex-wrap -mx-5">
              {props.highlights.map((box, i) => {
                return (
                  <div
                    key={`highlight-box-${i}`}
                    className={`${i > 0 ? 'mt-10 lg:mt-0' : ''} w-full lg:w-1/2 px-5`}
                  >
                    {box.image && (
                      <ImageWrap className="w-full rounded-lg mb-5" {...box.image} loading="lazy" />
                    )}
                    {box.title && (
                      <h3 className="text-xl lg:text-2xl pb-4 mb-4 border-b border-white border-opacity-20">
                        {box.title}
                      </h3>
                    )}
                    {box.content && <p className="text-gray-100 opacity-60">{box.content}</p>}
                  </div>
                )
              })}
            </div>
          </div>
        )}
      </div>
    </div>
  )
}
